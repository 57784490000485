import { Component, Vue } from "vue-property-decorator";
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'

import 'vue2-datepicker/index.css';
import { GET_LOG_REQUEST, RETRY_OUTSTANDING } from "../../../api/log-request";

Vue.use(VModal, { componentName: 'modal' })
@Component({
    components: {
        DatePicker
    },
    filters: {
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        pretty: function (value: string): string {
            return value ? JSON.stringify( value, null, "\t") : value
        }
    }
})
class Retry extends Vue {
    private model: Model;
    public retryData: any = []
    public gameTypeChecked: string[] = [ "ALLIN" ]
    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate()
    }

    public paginate = {
        page: 1,
        limit: 10
    }
    public pageSize = 0;
    public paginateShow = false;
    public dataLoading = false;
    public filterGameId = ""
    public jsonString = ""

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        
        this.addRouteName()
        this.dataLoading = true
       
        if(this.$route.query.buyInId) this.filterGameId = this.$route.query.buyInId.toString()
        if(this.$route.query.startDate) this.dateFilter.startDate = moment( this.$route.query.startDate.toString()).local().startOf('day').toDate()
        if(this.$route.query.endDate) this.dateFilter.endDate = moment( this.$route.query.endDate.toString()).local().endOf('day').toDate()

        await this.getLogRequest(this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    private addRouteName(): void {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public updateEndDate(): void {
        this.dateFilter.endDate = moment(this.dateFilter.endDate).endOf('day').toDate()
    }

    public async filterRetryData(): Promise<void> {
        this.paginate.page = 1
        await this.getLogRequest(this.paginate.page, this.paginate.limit)
    }

    public openModal(field: string, json: any): void{
        
        if(field == 'response_body'){
            this.jsonString = JSON.parse(json)
            this.$modal.show('modalRequest');
        }
        else {
            this.jsonString = json
            this.$modal.show('modalRequest');
        }
    }

    public hide(field: string): void {
        if (field == 'retry') {
            this.$modal.hide('modalRequest');
        }
    }

    public async selectDate(option: string): Promise<void> {
        switch (option) {
            case 'option1':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).subtract(1, 'days').toDate()
                break;
            case 'option2':
                this.dateFilter.startDate = moment().local().startOf('day').toDate()
                this.dateFilter.endDate = moment().local().endOf('day').toDate()
                break;
            case 'option3':
                this.dateFilter.startDate = moment().local(true).startOf('day').subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('day').subtract(1, 'days').toDate()
                break;
            case 'option4':
                this.dateFilter.startDate = moment().local(true).startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('week').toDate()
                break;
            case 'option5':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'week').startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'week').endOf('week').toDate()
                break;
            case 'option6':
                this.dateFilter.startDate = moment().local(true).startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('month').toDate()
                break;
            case 'option7':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'months').startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'months').endOf('month').toDate()
                break;
            case 'option8':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).add(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).add(1, 'days').toDate()
                break;
            default:
                break;
        }
        await this.getLogRequest(this.paginate.page, this.paginate.limit)
    }

    public async retryFn( buyInId: string, transactionId: string): Promise<void>{

        const result = await this.$swal({
            title: `${this.$t('RETRY.ARE_YOU_SURE_QUESTION')}`, 
            text: `${this.$t('RETRY.ARE_YOU_SURE_YOU_WANT_TO_RETRY_THIS_GAME')}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F5BC27',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#AFAFAF',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
            reverseButtons: true
        })

        if (!result.isConfirmed) {
            return
        }

        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });

        const state = {
            buyInId: buyInId,
            transactionId: transactionId,
            method: "re-settle"
        }

        const resultRetry = await RETRY_OUTSTANDING(state)

        if (resultRetry.success) {
            this.$swal({
                title: `${this.$t('RETRY.TITLE_SUCCESS').toString()}`,
                text: `${this.$t('RETRY.RETRY_SUCCESS').toString()}`,
                icon: "success",
                confirmButtonColor: '#4EB000',
                confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            });
            await this.getLogRequest(this.paginate.page, this.paginate.limit)
            loader.hide()
            return
        }

        this.$swal({
            text: resultRetry.error.message,
            icon: "error",
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
        });
        loader.hide()

    }

    public async reSendSettle(gameId): Promise<void> {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });

        const state = {
            gameId : gameId,
            method : "re-send-settle"
        }

        await RETRY_OUTSTANDING(state)
        await this.getLogRequest(this.paginate.page, this.paginate.limit)
        loader.hide()

    }

    public async clickCallback(number: number): Promise<void> {
        this.paginate.page = number
        await this.getLogRequest(this.paginate.page, this.paginate.limit)
    }

    private async getLogRequest(page: number, limit: number): Promise<void> {
        this.retryData = []
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        const lists = await GET_LOG_REQUEST(this.filterGameId, (this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString(), JSON.stringify(this.gameTypeChecked),page, limit)
        this.retryData = lists.data[0];

        if (this.retryData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.retryData.metadata.length; i++, k++) {
                this.retryData.metadata[i].no = k - this.paginate.limit
            }
            if (this.retryData.pageInfo[0].count > this.paginate.limit) {
                this.paginateShow = true
            }
            this.pageSize = this.retryData.pageInfo[0].size;
        } else {
            this.retryData = []
            this.paginateShow = false
        }
        loader.hide()
    }
}

export default Retry